import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { authToken } from '../../services/token';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import { toast } from 'react-toastify';
import { captureSubscriptionApi, checkOutSubscriptionApi, fetchAllSubscriptionsApi } from '../../services/apis/subscriptionApi';
import './subscription.css';
import PayPalConfig from '../../services/paypalConfig';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { setRemainingCase } from '../../redux/slices/caseRemainingSlice';
import Loader from '../../components/loader/Loader';
import RemainingCase from '../../components/remainingCase/RemainingCase';
import { ENVS } from '../../utils/constant';

const Subscription = () => {

  const [subscriptionData, setSubscriptionData] = useState([]);
  const loading = useSelector((state) => state.utilsObj.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = authToken();
  const checksRemaining = useSelector(state => state.caseRemainingObj.casesRemaining);
  const { REACT_APP_FRONTEND_IMG_PATH } = ENVS;

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  //fetch list of all subscriptions
  const fetchSubscriptions = async () => {

    try {

      dispatch(requestSent());
      const response = await fetchAllSubscriptionsApi(token);
      if (response.status === 200) {
        setSubscriptionData(response.data.data);
        dispatch(responseRecieved());
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError());

      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);

      }
      return toast.error('Something went wrong');
    }
  }


  //create an order to purchase the subscription
  const handleCreateOrder = async (id) => {

    try {

      const response = await checkOutSubscriptionApi(id, token);
      if (response.status === 200) {
        const orderIDVar = response.data.data;
        return orderIDVar;
      }

    } catch (error) {
      console.log('error=>', error);
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate('/login')
      }
      return toast.error('Something went wrong');
    }
  }


  //capture an order to purchase the subscription
  const handleApprove = async (data) => {

    try {

      const response = await captureSubscriptionApi(data.orderID, token);
      if (response.status === 200) {
        dispatch(setRemainingCase(response.data.data.case_remaining))
        navigate('/home');
        toast.success('Subscription purchased successfully');
      }

    } catch (error) {

      console.log('error=>', error);
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);

      }
      return toast.error('Something went wrong');
    }
  };


  //handle cancellation of order
  const handleCancelOrder = async (data) => {

    try {

      return toast.error('Payment flow cancelled');
      // const response = await cancelSubscriptionApi(data.orderID, token);
      // console.log('response', response);
      // if (response.status === 200) {
      //   toast.error(response.data.message);
      // } 

    } catch (error) {

      console.log('error=>', error);

      // if (error.response.status === 400 || error.response.status === 500) {
      //   return toast.error(error.response.data.error);
      // }
      // return toast.error('Something went wrong');
    }
  }


  //handle unexpected events
  const handleErrorOrder = (err) => {

    console.log('errorinorder=======', err);
    return toast.error('Something went wrong with the payment');
  }

  return (
    <>
      {
        loading ? (
          <Loader />
        ) : (


          <PayPalConfig>
            <UserNavbar />
            <div className="subscription-page-container">
              <div className='account-box-header'>
                <div className='flex-div'>
                  <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan" className='lessthan-2' onClick={() => navigate(-1)} /><h1>Subscriptions</h1>
                </div>
                <div className='custom-ml'>
                  <RemainingCase />
                </div>
              </div>
              {
                subscriptionData.length === 0 ? (
                  <div className="no-data">
                    <h1>No Subscription plans found</h1>
                  </div>
                ) : (
                  <div className="subscription-cards-container">
                    {subscriptionData.map((val) => {
                      return (
                        <div className="subscription-cards" key={val.id}>
                          <h1 className="subscription-card-title">
                            {val.title}
                          </h1>
                           {
                            val.title === 'Enterprise' && (
                              <div>
                                <div className='customized-div'>Please contact us for a bespoke quote.</div>
                              </div>
                            )
                           }
                          {
                            val.title !== 'Enterprise' && (
                              <>
                                <div className="subscription-card-price">
                                  £ {val.price}
                                </div>
                                <div className="subscription-card-checks">
                                  <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/blackcheck.png`} alt="" /> {val.total_case} Total case
                                </div>
                                <div className="custom-container">
                                  <div
                                    //  className='subscription-card-button' 
                                    className='customized-button'>{checksRemaining === '0' ? 'Buy' : 'Upgrade'}</div>
                                  <div className="paypal">
                                    <PayPalButtons
                                      style={{ layout: "vertical", label: 'buynow', size: "small", height: 40, tagline: "false", borderRadius: 10, }}
                                      fundingSource={'paypal'}
                                      // createOrder={(data, actions) => {
                                      //   // Create order logic
                                      //   console.log('data', data);
                                      //   console.log('actions', actions);

                                      // return actions.order.create({
                                      //   description: `Purchase subscripton id ${val.id}`,
                                      //   purchase_units: [{
                                      //     amount: {
                                      //       value: val.price,
                                      //       currency_code: "GBP"
                                      //     }
                                      //   }]
                                      // });

                                      // }}
                                      createOrder={() => handleCreateOrder(val.id)}
                                      onApprove={(data) => handleApprove(data)}
                                      onCancel={(data) => handleCancelOrder(data)}
                                      onError={(err) => handleErrorOrder(err)}
                                    />
                                  </div>
                                </div>
                              </>
                            )
                          }


                        </div>
                      )
                    })}
                  </div>
                )}
            </div>
          </PayPalConfig>
        )
      }
    </>
  )
}

export default Subscription