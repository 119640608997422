import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import { authToken } from '../../services/token';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { fetchSelfCaseRequestApi } from '../../services/apis/caseApi';
import './commonCases.css';
import { USER_ROLES } from '../../utils/constant';
import Loader from '../../components/loader/Loader';
import {ENVS} from '../../utils/constant';


const SelfCasesHistory = () => {

  const navigate = useNavigate();
  const token = authToken();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.utilsObj.loading);
  const role = localStorage.getItem('role');
  const [selfCaseHistoryData, setSelfCaseHistoryData] = useState([]);

  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    limit: 10,
    totalPages: 0
  });

  const {REACT_APP_FRONTEND_IMG_PATH, REACT_APP_REQUEST_URL} = ENVS; 

  useEffect(() => {
    fetchSelfCaseHistory()
  }, [paginationData.pageNo]);

  const fetchSelfCaseHistory = async (req, res) => {

    try {
      dispatch(requestSent());

      const response = await fetchSelfCaseRequestApi(paginationData.pageNo, token);

      if (response.status === 200) {
        setSelfCaseHistoryData(response.data.data.rows);
        setPaginationData({
          pageNo: response.data.pageNo,
          limit: response.data.perPage,
          totalPages: response.data.totalPages
        });
        dispatch(responseRecieved())
      }


    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));
    }
  }

  const handlePageClick = (data) => {
    const updatedPageNo = data.selected + 1; // Pagination starts from 0, so adding 1
    setPaginationData({ ...paginationData, pageNo: updatedPageNo });
  };


  return (
    
      loading ? <Loader/> : (
        <>
          <UserNavbar />
          <div className="common-page">
            <div className='account-box-header'>
              <div className='flex-div'>
                <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan" style={{ marginRight: '16px', cursor: 'pointer' }} onClick={() => navigate(-1)} /><h1>Cases History</h1>
              </div>
              {
                role === USER_ROLES.BROKER && (
                  <div className='staff-head-btns'>
                    <button onClick={() => navigate('/cases-history/staff')}>Staff Cases</button>
                  </div>
                )
              }
            </div>
    
            <div className="table-container table-horizontal-overflow">
              {
                selfCaseHistoryData.length === 0 ? (
                  <div className="no-data">
                    <h1>No case history found</h1>
                  </div>
                ) : (
                  <table className="transaction-table">
                    <thead>
                      <tr className='table-row'>
                        <th className='table-head'>S.No.</th>
                        <th className='table-head'>Name</th>
                        {role === USER_ROLES.BROKER && <th className='table-head'>Broker Name</th>}
                        <th className='table-head'>1st Payslip</th>
                        <th className='table-head'>2nd Payslip</th>
                        <th className='table-head'>3rd Payslip</th>
                        <th className='table-head'>1st Bank Statement</th>
                        <th className='table-head'>2nd Bank Statement</th>
                        <th className='table-head'>3rd Bank Statement</th>
                        <th className='table-head'>1st Credit Card Statement</th>
                        <th className='table-head'>2nd Credit Card Statement</th>
                        <th className='table-head'>3rd Credit Card Statement</th>
                        <th className='table-head'>1st Joint Account Statement</th>
                        <th className='table-head'>2nd Joint Account Statement</th>
                        <th className='table-head'>3rd Joint Account Statement</th>
                        <th className='table-head'>P60</th>
                        <th className='table-head'>Employment Contract</th>
                        <th className='table-head'>1st Tax year overview</th>
                        <th className='table-head'>2nd Tax year overview</th>
                        <th className='table-head'>3rd Tax year overview</th>
                        <th className='table-head'>1st Tax year calculation</th>
                        <th className='table-head'>2nd Tax year calculation</th>
                        <th className='table-head'>3rd Tax year calculation</th>
                        <th className='table-head'>1st Company Account</th>
                        <th className='table-head'>2nd Company Account</th>
                        <th className='table-head'>3rd Company Account</th>
                        <th className='table-head'>Case Status</th>
                        <th className="table-head">Document Status</th>
                        <th className="table-head">Score</th>
                        <th className="table-head"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        selfCaseHistoryData.map((val, index) => {
                          return (
                            <tr className='table-row' key={index}>
                              <td className='table-data'>{index + 1 + (paginationData.pageNo - 1) * paginationData.limit}</td>
                              <td className='table-data'>{val?.name}</td>
                              {role === USER_ROLES.BROKER && <td className='table-data'>{val?.broker_name === null ? 'Not available' : val.broker_name}</td>}
                              <td className="table-data">
                                  <span
                                    className={`view-texts ${!val?.payslip_1 ? 'view-texts-disabled' : ''}`}
                                    onClick={!val?.payslip_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.payslip_1}`
                                    ))}>View payslip</span>
                                </td>
                                <td className="table-data">
                                  <span
                                    className={`view-texts ${!val?.payslip_2 ? 'view-texts-disabled' : ''}`}
                                    onClick={!val?.payslip_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.payslip_2}`
                                    ))}>View payslip</span>
                                </td>
                                <td className="table-data">
                                  <span
                                    className={`view-texts ${!val?.payslip_3 ? 'view-texts-disabled' : ''}`}
                                    onClick={!val?.payslip_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.payslip_3}`
                                    ))}>View payslip</span>
                                </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.bank_statement_4 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.bank_statement_4 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.bank_statement_4}`))}>View bank statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.bank_statement_5 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.bank_statement_5 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.bank_statement_5}`))}>View bank statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.bank_statement_6 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.bank_statement_6 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.bank_statement_6}`))}>View bank statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.credit_card_statement_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.credit_card_statement_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.credit_card_statement_1}`))}>View Credit Card statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.credit_card_statement_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.credit_card_statement_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.credit_card_statement_2}`))}>View Credit Card statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.credit_card_statement_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.credit_card_statement_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.credit_card_statement_3}`))}>View Credit Card statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.joint_account_statement_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.joint_account_statement_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.joint_account_statement_1}`))}>View Joint acc statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.joint_account_statement_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.joint_account_statement_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.joint_account_statement_2}`))}>View Joint acc statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.joint_account_statement_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.joint_account_statement_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.joint_account_statement_3}`))}>View Joint acc statement</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.p60 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.p60 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.p60}`))}>View P60</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.employment_contract ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.employment_contract ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.employment_contract}`))}>View Employment contract</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_summary_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_summary_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_summary_1}`))}>1st Tax Year Overview</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_summary_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_summary_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_summary_2}`))}>2nd Tax Year Overview</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_summary_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_summary_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_summary_3}`))}>3rd Tax Year Overview</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_calculation_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_calculation_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_calculation_1}`))}>1st Tax Year Calculation</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_calculation_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_calculation_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_calculation_2}`))}>2nd Tax Year Calculation</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.tax_calculation_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.tax_calculation_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.tax_calculation_3}`))}>3rd Tax Year Calculation</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.company_account_1 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.company_account_1 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.company_account_1}`))}>1st Company Account</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.company_account_2 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.company_account_2 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.company_account_2}`))}>2nd Company Account</span>
                              </td>
                              <td className='table-data'>
                                <span
                                  className={`view-texts ${!val?.company_account_3 ? 'view-texts-disabled' : ''}`}
                                  onClick={!val?.company_account_3 ? null : () => window.open(encodeURI(`${REACT_APP_REQUEST_URL}${val?.company_account_3}`))}>3rd Company Account</span>
                              </td>
                              <td className="table-data" style={{
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                                color:
                                  val?.approval_status === 'created' ? '#000000' :
                                    val?.approval_status === 'pending' ? '#ffc107' :
                                      val?.approval_status === 'approved' ? '#28a745' :
                                        val?.approval_status === 'rejected' ? 'red' :
                                          val?.approval_status === 'failed' ? 'red' :
                                            'black'
                              }}>{
                                val?.approval_status !== 'failed' ? 
                                  val?.approval_status : 
                                    <span> {val?.approval_status} <br/> <span style={{fontSize: '12px'}}>{val?.failed_reason !== null ? `(${val?.failed_reason})` : ''}</span></span>
                                }</td>
                              <td className="table-data">
                                {
                                  val.payslip_1 === null && val.bank_statement_4 === null ?
                                    <button className='custom-badge-success custom-upload-btn' onClick={() => navigate(`/docs-upload?case_request_id=${val.id}`)}>Upload</button> :
                                    <button className='custom-badge-secondary  custom-uploaded-btn'>Uploaded</button>
                                }
                              </td>
                              <td className='table-data'>{val?.score != null ? `${val.score}%` : 'NA'}</td>
                              <td className="table-data" style={{ minWidth: '100px', overflowX: 'auto' }}>
                                <span
                                  className='text-btn'
                                  onClick={() => navigate(`/case-detail?id=${val.id}`)}
                                >
                                  Full Info
                                </span>
                              </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
                )
              }
    
              {
                selfCaseHistoryData.length > 0 && (
                  <ReactPaginate
                    pageCount={paginationData.totalPages}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={3}
                    forcePage={paginationData.pageNo - 1 }
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                  />
                )
              }
            </div>
            {/* {showPdfViewer &&
            <PdfComp pdfPath={pdfPath} />
          } */}
          </div>
        </>

        

      )
    
  )

}

export default SelfCasesHistory