import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import './report.css';
import NavigationButtons from '../../components/navigationButtons/NavigationButtons';
import { generateCaseReportApi } from '../../services/apis/caseApi';
import { authToken } from '../../services/token';
import Loader from '../../components/loader/Loader';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { setRemainingCase } from '../../redux/slices/caseRemainingSlice';
import {ENVS} from '../../utils/constant';

const Report = () => {

    const navigate = useNavigate();
    const token = authToken();
    const location = useLocation();
    const loading = useSelector(state => state.utilsObj.loading);
    const searchParams = new URLSearchParams(location.search);
    const caseRequestId = searchParams.get('case_request_id');
    const isEdit = searchParams.get("isEdit") || false;
    const checksRemaining = useSelector(state => state.caseRemainingObj.casesRemaining);
    const dispatch = useDispatch();
    const [scoreData, setScoreData] = useState(0);
    const {REACT_APP_FRONTEND_IMG_PATH} = ENVS;

    useEffect(() => {
        generateReport()
    }, []);

    const generateReport = async () => {

        try {

            dispatch(requestSent());
            const response = await generateCaseReportApi(caseRequestId, token, isEdit);
            if (response.status === 200) {
                setScoreData(response.data.data)
                console.log('score', response.data.data)
                let typeCastValue = Number(checksRemaining)
                typeCastValue = typeCastValue - 1
                dispatch(setRemainingCase(typeCastValue))
                dispatch(responseRecieved());
            }

        } catch (error) {

            console.log('error=>', error);
            dispatch(recievedError(error));
            if (error.response.status === 400) {
                if (error.response.data.error === 'A report for this case has already been generated') {
                    toast.error('A report for this case has already been generated')
                    navigate(`/case-detail?id=${caseRequestId}`);
                    return;
                } else {
                    toast.error(error.response.data.error);
                    return;
                }
            } else if (error.response.status === 403) {
                return navigate('/login')
            }
            else if (error?.response?.data?.error?.status === 429) {
                toast.error('Too many requests incoming try after some time');
                return;
            }
            else if (error?.response?.data?.error?.status === 529) {
                toast.error('Mortgage server overloaded try generating report after some time');
                return;
            }
            return toast.error('Something went wrong');
        }
    }

    return (
        <>
            <UserNavbar />
            <div className='common-container-2'>
                {/* <div className='flex-heading-container'>
                    <button className='case-request-btn inactive-focus'>Case Requests</button>
                    <img src="/assets/icons/lessthan.png" alt="lessthan" className='lessthan' />
                    <button className='case-request-btn inactive-focus'>Documents Upload</button>
                    <img src="/assets/icons/lessthan.png" alt="lessthan" className='lessthan' />
                    <button className='case-request-btn'>Report Generation</button>
                </div> */}
                <NavigationButtons buttons={[{ label: 'Case Requests', isActive: false }, { label: 'Documents Upload', isActive: false }, { label: 'Report Generation', isActive: true }]} />
            </div>

            {
                loading ? (
                    <Loader report="Processing" msg="Your report is being generated do not leave this page"/>
                ) : (
                    <div className="create-case-container">
                        <div className='create-new-case-header'>
                            <div><img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="backarrow" className='back-arrow' style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} /></div>
                        </div>

                        <div className="success-report-box">
                            <div className="success-report-message">
                                <div>
                                    <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/bigcheckmark.png`} alt='bigcheckmark' />
                                </div>
                                <div>
                                    <h1> Report Generated Successfully!</h1>
                                    <h1>Score {scoreData}%</h1>
                                    <button className='common-btn-style' onClick={() => navigate(`/case-detail?id=${caseRequestId}`)}>View Info</button>
                                </div>
                            </div>
                        </div>
                    </div>
                 ) 
             } 
        </>
    )
}

export default Report