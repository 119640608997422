import React from 'react'
import { useNavigate } from 'react-router-dom';
import './verificationSuccess.css'
import {ENVS} from '../../utils/constant';

const VerificationSuccess = () => {

  const navigate = useNavigate();

  return (
    <>
      <div style={{border: '1px solid transparent'}}>
        <div className='verification-success-card'>
        <div className="verify-box" style={{textAlign:'center'}}>
          <div className="right-img">
            <img src={`${ENVS.REACT_APP_FRONTEND_IMG_PATH}/assets/icons/check.png`} alt="img" />
          </div>
          <h3 className='verified-heading'>
            Verified!!
          </h3>
          <p className='verified-one-liner'>You have successfully <br/> verified the account</p>
          <button className='confirm-btn' onClick={() => navigate('/subscription')}>Done</button>
        </div>
        </div>
      </div>
    </>
  )
}

export default VerificationSuccess