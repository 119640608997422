import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ScoreChart = ({ totalScore }) => {
  // Calculate fraud and valid percentages
  const fraudPercentage = 100 - totalScore;
  const validPercentage = totalScore;

  // Prepare the data for the pie chart
  const data = [
    { name: 'Safe', value: validPercentage },
    { name: 'Fraud Detection', value: fraudPercentage },
  ];

  // Define chart colors
  const COLORS = ['#7856FF', '#ffcb17']; // Purple for safe, Yellow for fraud detection

  return (
    <div className="score-integrity-chart">
      {/* <h2>Fraud Detection Analysis</h2> */}
      
      <ResponsiveContainer width="100%" height={350}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            outerRadius={120}
            innerRadius={80}
            labelLine={false}
            label={({ name, value }) => `${name}: ${value}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index]} style={{outline: 'none'}} />
            ))}
          </Pie>
          <Tooltip />
          <Legend verticalAlign="top" align="center" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ScoreChart;
