module.exports = {

    ENVS: {
        REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
        REACT_APP_REQUEST_URL: process.env.REACT_APP_REQUEST_URL,
        REACT_APP_FRONTEND_IMG_PATH: process.env.REACT_APP_FRONTEND_IMG_PATH,
        REACT_APP_PAYPAL_SANDBOX_CLIENT_ID: process.env.REACT_APP_PAYPAL_SANDBOX_CLIENT_ID,
        REACT_APP_PAYPAL_SANDBOX_SECRET_KEY: process.env.REACT_APP_PAYPAL_SANDBOX_SECRET_KEY,
        REACT_APP_PAYPAL_PROD_CLIENT_ID: process.env.REACT_APP_PAYPAL_PROD_CLIENT_ID,
        REACT_APP_PAYPAL_PROD_SECRET_KEY: process.env.REACT_APP_PAYPAL_PROD_SECRET_KEY,
        REACT_TEST_CARD: process.env.REACT_TEST_CARD,
        REACT_TEST_CARD_EXP: process.env.REACT_TEST_CARD_EXP
    },

    DETAIL_MATCH_STATUS: {
        FULL: 'full',
        PARTIAL: 'partial',
        None: 'none'
    },

    REPORT_CRITERIAS: {
        COMPANY_ACCOUNT: 'Company Account',
        CREDIT_CARD_STATEMENT_INFO: 'Credit Card Statement Info', 
        EMPLOYMENT_CONTRACT:'Employment Contract',
        FRAUD_DETECTION: 'Fraud Detection',
        JOINT_ACCOUNT_STATEMENT_INFO: 'Joint Account Statement Info',
        PAYSLIP_DATA: 'PaySlip Data',
        PAYSLIP_AND_BANKSTATEMENT_COMPARISION: 'Payslip and bankStatement comparision',
        MATCHING_NET_PAYS:'Matching net pays',
        MATCHING_NAMES_AND_ADDRESSES:'Matching names and addresses',
        P60_DATA:'P60 Data',
        TAX_SUMMARY: "Tax Overview",
        TAX_CALCULATION: "Tax Calculation",
        VULNERABILITY_DETECTION: "Vulnerability Detection"
    },

    STAGES: {
        BASIC_DETAILS: 'basicDetails',
        VERIFICATION: 'verification',
        VERIFIED: 'verified',
    },

    URI_PREFIX :  {
        development:'',
        test:'',
        production:'',
    },

    USER_STATUS: {
        ACTIVE: 'Active',
        BLOCKED: 'Blocked',
        DELETED: 'Deleted'
    },

    USER_ROLES: {
        BROKER: 'Broker',
        STAFF: 'Staff'
    },
}