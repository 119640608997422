import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
// import ReactPaginate from 'react-paginate';
import UserNavbar from '../../components/userNavbar/UserNavbar'
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { fetchCaseDetailApi, generateCaseBudgetPlannerApi, fetchBudgetPlannerApi } from '../../services/apis/caseApi';
import { authToken } from '../../services/token';
import Loader from '../../components/loader/Loader';
// import moment from 'moment';
import './caseDetail.css';
import './commonCases.css';
import DataSection from '../../components/dataSection/DataSection';
import { ENVS, DETAIL_MATCH_STATUS, REPORT_CRITERIAS } from '../../utils/constant'
import { toast } from 'react-toastify';
import CaseCard from '../../components/caseCard/CaseCard';
import ScoreChart from '../../components/scoreChart/ScoreChart';
import { extractPdfName, formatBudgetCategoryName } from '../../utils/helper';


const CaseDetail = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = authToken();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const caseId = searchParams.get('id');
  const loading = useSelector((state) => state.utilsObj.loading);
  const [isBudgetPlannerLoader, setIsBudgetPlannerLoader] = useState(false);
  const [caseDetail, setCaseDetail] = useState(null); // State to store the fetched case detail
  const [reports, setReports] = useState({
    payslipReport: [],
    gamblingAndUtlityData: {},
    netPayMatchData: [],
    nameAndAddress: [],
    p60Data: {},
    employmentContract: {},
    taxOverView: [],
    taxCalculation: [],
    creditCardStatementInfo: [],
    jointAccountStatementInfo: [],
    companyAccount: [],
    fraudDetection: [],
    vulnerabilityDetection: []
  });

  // const [budgetPlanner, setBudgetPlanner] = useState([])
  const [budgetAnalytics, setBudgetAnalytics] = useState([])

  const [tabs, setTabs] = useState({
    activeBankStatement: 0,
    activePayslip: 0,
    activeTaxOverview: 0,
    activeTaxCalculation: 0,
    activeCreditCardStatementInfo: 0,
    activeJointAccountStatementInfo: 0,
    activeCompanyAccount: 0
  });

  const userId = Number(localStorage.getItem('userId'));
  const errorNA = <span className='error-red'>NA</span>;

  useEffect(() => {
    fetchCaseDetail();
  }, []);

  useEffect(() => {
    fetchBugetPlannerAnalytics()
  }, [])

  const fetchCaseDetail = async () => {
    try {
      dispatch(requestSent());
      const response = await fetchCaseDetailApi(caseId, token);
      if (response.status === 200) {
        const data = response.data.data;
        setCaseDetail(data);
        const newReports = {
          payslipReport: [],
          gamblingAndUtlityData: {},
          netPayMatchData: [],
          nameAndAddress: [],
          p60Data: {},
          employmentContract: {},
          taxOverView: [],
          taxCalculation: [],
          creditCardStatementInfo: [],
          jointAccountStatementInfo: [],
          companyAccount: [],
          fraudDetection: [],
          vulnerabilityDetection: []
        };
        data.CaseReports.forEach(report => {
          const parsedResult = JSON.parse(report.result);
          // console.log('parsedResult', parsedResult)
          if (report.criteria === REPORT_CRITERIAS.PAYSLIP_DATA) {
            newReports.payslipReport = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.PAYSLIP_AND_BANKSTATEMENT_COMPARISION) {
            newReports.gamblingAndUtlityData = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.MATCHING_NET_PAYS) {
            newReports.netPayMatchData = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.MATCHING_NAMES_AND_ADDRESSES) {
            newReports.nameAndAddress = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.P60_DATA) {
            newReports.p60Data = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.EMPLOYMENT_CONTRACT) {
            newReports.employmentContract = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.TAX_SUMMARY) {
            newReports.taxOverView = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.TAX_CALCULATION) {
            newReports.taxCalculation = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.CREDIT_CARD_STATEMENT_INFO) {
            newReports.creditCardStatementInfo = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.JOINT_ACCOUNT_STATEMENT_INFO) {
            newReports.jointAccountStatementInfo = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.COMPANY_ACCOUNT) {
            newReports.companyAccount = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.FRAUD_DETECTION) {
            newReports.fraudDetection = parsedResult;
          } else if (report.criteria === REPORT_CRITERIAS.VULNERABILITY_DETECTION) {
            newReports.vulnerabilityDetection = parsedResult;
          }
        });
        setReports(newReports);
        dispatch(responseRecieved());
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));
    }
  }

  const generateBudgetPlanner = async () => {
    try {
      setIsBudgetPlannerLoader(true)
      dispatch(requestSent())

      const response = await generateCaseBudgetPlannerApi(caseId, token)

      if (response.status === 200) {
        dispatch(responseRecieved())
        setIsBudgetPlannerLoader(false)
        fetchBugetPlannerAnalytics()
        toast.success(response?.data?.data?.message)
      }


    } catch (error) {
      console.log("error=>", error);
      setIsBudgetPlannerLoader(false);
      dispatch(responseRecieved())
      // if (error?.response?.data) {
      //   toast.error(error?.response?.data?.error)
      // }
      if (error?.response?.data?.error?.status === 429) {
        toast.error('Too many requests incoming try after some time');
        return;
      }
      else if (error?.response?.data?.error?.status === 529) {
        toast.error('Mortgage server overloaded try generating budget planner after some time');
        return;
      }
      return toast.error('Something went wrong');
    }
  }

  const fetchBugetPlannerAnalytics = async () => {

    try {

      const response = await fetchBudgetPlannerApi(caseId, token);
      if (response?.status) {
        setBudgetAnalytics(response.data.data)
      }

    } catch (error) {
      console.log('error=>', error);

    }
  }

  function validatePaymentMethod(paySlip) {
    try {
      const payMethod = paySlip.pay_method ? paySlip.pay_method.toLowerCase() : null;
      const employeeNo = paySlip.employee_no ? Number(paySlip.employee_no) : null;

      if (!payMethod) {
        return errorNA
      }

      if (payMethod === "bacs" || payMethod === "bgc") {
        return <span className='full-match-text'>{paySlip?.pay_method}</span>
      }

      if ((payMethod === "faster payment" || payMethod === "cr") && employeeNo > 50) {
        return errorNA;
      }

      if ((payMethod === "faster payment" || payMethod === "cr") && employeeNo <= 50) {
        return <span className='full-match-text'>{paySlip?.pay_method}</span>
      }

      return <span className='error-red'>{paySlip?.pay_method} (Neither bacs nor bgc and also if is faster payment or cr than employeeNo is greater than 50.)</span>;
    } catch (error) {
      console.log('errorhai', error)
      return errorNA;
    }
  }

  function validateNinEmpNoTaxCode(key1, key2, key3, index, keyName) {
    try {
      if (key1) key1 = String(key1).replace(/ /g, '');
      const keys = [key1];

      if (key2) key2 = String(key2).replace(/ /g, '');
      if (key3) key3 = String(key3).replace(/ /g, '');

      if (key2) keys.push(key2);
      if (key3) keys.push(key3);

      const val = keys[index];
      if (!val) {
        return errorNA;
      }

      if (keyName === 'Tax code' && !/L/i.test(val)) {
        if (/BR/i.test(val) && !/CBR/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the basic rate (usually used if you've got more than one job or pension)</span>;
        } else if (/D0/i.test(val) && !/CD0/i.test(val) && !/SD0/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the higher rate (usually used if you've got more than one job or pension)</span>;
        } else if (/D1/i.test(val) && !/CD1/i.test(val) && !/SD1/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the additional rate (usually used if you've got more than one job or pension)</span>;
        } else if (/0T/i.test(val) && !/S0T/i.test(val) && !/C0T/i.test(val)) {
          return <span className='error-red'>{val}: Your Personal Allowance has been used up, or you've started a new job and your employer does not have the details they need to give you a tax code</span>;
        } else if (/S0T/i.test(val)) {
          return <span className='error-red'>{val}: Your Personal Allowance (Scotland) has been used up, or you've started a new job and your employer does not have the details they need to give you a tax code</span>;
        } else if (/SBR/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the basic rate in Scotland (usually used if you've got more than one job or pension)</span>;
        } else if (/SD0/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the intermediate rate in Scotland (usually used if you've got more than one job or pension)</span>;
        } else if (/SD1/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the higher rate in Scotland (usually used if you've got more than one job or pension)</span>;
        } else if (/SD2/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the advanced rate in Scotland (usually used if you've got more than one job or pension)</span>;
        } else if (/SD3/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the top rate in Scotland (usually used if you've got more than one job or pension)</span>;
        } else if (/S/i.test(val)) {
          return <span className='error-red'>{val}: Your income or pension is taxed using the rates in Scotland</span>;
        } else if (/C/i.test(val) && !/C0T/i.test(val) && !/CBR/i.test(val) && !/CD0/i.test(val) && !/CD1/i.test(val)) {
          return <span className='error-red'>{val}: Your income or pension is taxed using the rates in Wales</span>;
        } else if (/C0T/i.test(val)) {
          return <span className='error-red'>{val}: Your Personal Allowance (Wales) has been used up, or you've started a new job and your employer does not have the details they need to give you a tax code</span>;
        } else if (/CBR/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the basic rate in Wales (usually used if you've got more than one job or pension)</span>;
        } else if (/CD0/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the higher rate in Wales (usually used if you've got more than one job or pension)</span>;
        } else if (/CD1/i.test(val)) {
          return <span className='error-red'>{val}: All your income from this job or pension is taxed at the additional rate in Wales (usually used if you've got more than one job or pension)</span>;
        } else if (/K/i.test(val)) {
          return <span className='error-red'>{val}: Tax codes with 'K' at the beginning mean you have income that is not being taxed another way and it's worth more than your tax-free allowance</span>;
        } else if (/NT/i.test(val)) {
          return <span className='error-red'>{val}: You're not paying any tax on this income</span>;
        } else if (/T/i.test(val)) {
          return <span className='error-red'>{val}: Your tax code includes other calculations to work out your Personal Allowance</span>;
        } else if (/M/i.test(val) && !/W1|M1|X/i.test(val)) {
          return <span className='error-red'>{val}: Marriage Allowance: you've received a transfer of 10% of your partner's Personal Allowance</span>;
        } else if (/N/i.test(val)) {
          return <span className='error-red'>{val}: Marriage Allowance: you've transferred 10% of your Personal Allowance to your partner</span>;
        } else if (/W1|M1|X/i.test(val)) {
          return <span className='error-red'>{val}: This is an emergency tax code</span>;
        } else {
          return <span className='error-red'>{val}: Invalid tax code</span>;
        }
      }

      // if (keyName === 'Tax code' && new Set(keys).size !== 1) {
      //   return <span className='error-red'>{val} ({keyName} is different in payslips)</span>;
      // }

      if (keyName !== 'Tax code' && new Set(keys).size !== 1) {
        return <span className='error-red'>{val} ({keyName} is different)</span>;
      }

      if (keyName === 'Tax code') {
        return <span className='full-match-text'>{val} You're entitled to the standard tax-free Personal Allowance.</span>
      }
      return val;
    } catch (error) {
      return errorNA;
    }
  }

  const { payslipReport, gamblingAndUtlityData, netPayMatchData, nameAndAddress, p60Data, employmentContract, taxOverView, taxCalculation, creditCardStatementInfo, jointAccountStatementInfo, companyAccount, fraudDetection, vulnerabilityDetection } = reports;

  // console.log('creditCardStatementInfo', creditCardStatementInfo)
  // console.log('jointAccountStatementInfo', jointAccountStatementInfo)

  const isEmptyObject = (obj) => Object.keys(obj).length === 0;
  return (
    <>
      {
        loading ?
          (
            isBudgetPlannerLoader ? <Loader report="Processing" msg="Your planner is being generated do not leave this page" /> : <Loader />
          ) : (
            <>
              <UserNavbar />
              <div className="common-page">

                <div className='account-box-header'>
                  <div className='flex-div'>
                    <h1 className='flex-center-cs' style={{ marginLeft: '15px' }}>Mortgage Application Report
                      <img onClick={() => navigate(`/docs-upload?case_request_id=${caseId}&isEdit=true`)} style={{ marginLeft: '15px', cursor: "pointer" }} src={`${ENVS.REACT_APP_FRONTEND_IMG_PATH}/assets/icons/edit.png`} width="25" alt="edit-case" /></h1>
                  </div>
                  <div>
                    <p className='back-to-home' onClick={() => navigate('/home')}>Back to home</p>
                  </div>
                </div>

                {caseDetail ? (
                  <>
                    <div className="case-parent">
                      <CaseCard
                        caseDetail={caseDetail}
                        userId={userId}
                        caseId={caseId}
                        navigate={navigate}
                        errorNA={errorNA}
                      />
                    </div>
                  </>
                ) : (
                  <h1 className='no-data'>No case data</h1>
                )}

                {
                  caseDetail && caseDetail.score ? (
                    <>
                      <div className='parent-container'>

                        <div className="cs-flex-container">
                          {/* Bank Statements Column */}
                          <div className="cs-column resp-w">
                            <div className="tabs-container">
                              {
                                nameAndAddress.map((val, index) => (
                                  index < 3 && (
                                    <button
                                      key={`bank-statement-${index}`}
                                      onClick={() => setTabs(prevState => ({
                                        ...prevState,
                                        activeBankStatement: index
                                      }))}
                                      className={`${index === tabs.activeBankStatement ? 'tab-button active-tab' : 'tab-button'}`}
                                    >
                                      Bank Statement {index + 1}
                                    </button>
                                  )
                                ))
                              }
                            </div>

                            <div className="cs-flex-container">
                              <div className='cs-column'>

                                <DataSection
                                  title="Gambling websites"
                                  data={gamblingAndUtlityData?.gambling_websites}
                                  noDataMessage="No transactions related to gambling websites"
                                  color='green'
                                />

                                {/* Commenting utility data now as we have budget planner */}
                                {/* <DataSection
                                  title="Utility Payments"
                                  data={gamblingAndUtlityData?.utility_payments}
                                  noDataMessage="No transactions related to utility websites"
                                  color="green"
                                /> */}
                              </div>
                              {
                                nameAndAddress.map((val, index) => {
                                  if (index !== tabs.activeBankStatement) return null;
                                  const transactions = netPayMatchData[`bankStatement${index + 1}Transactions`];
                                  const filteredData = netPayMatchData.data.filter(
                                    (transaction) => transaction.bank_statement_no == tabs.activeBankStatement + 1
                                  );

                                  function getMatch(value) {
                                    if (value === 'full') {
                                      return 'full-match-text'
                                    } else if (value === 'partial') {
                                      return 'partial-match-text'
                                    } else if (value === 'none') {
                                      return 'none-match-text'
                                    } else {
                                      return ''
                                    }
                                  }

                                  return (

                                    <div key={index} className='cs-column'>
                                      <h4 className="custom-mb">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Bank Statement Data</h4>
                                      <p className="custom-mb">
                                        <span className='card-span'>Name on bank statement:</span>
                                        <span className={getMatch(val?.name_match)}>{val?.bank_statement_owner_name || errorNA}</span>
                                      </p>
                                      <p className="custom-mb">
                                        <span className='card-span'>Account Number:</span>{val?.bank_statement_account_number || errorNA}
                                      </p>
                                      <p className="custom-mb">
                                        <span className='card-span'>Address on bank statement:</span>
                                        <span className={getMatch(val?.address_match)}>{val?.bank_statement_owner_address || errorNA}</span>
                                      </p>
                                      <p className="custom-mb">
                                        <span className='card-span'>Transactions on bank statement:</span>
                                        {transactions > 40 ? transactions : <span className='error-red'>{transactions}</span>}
                                      </p>
                                      <p className="custom-mb">
                                        <span className='card-span'>FileName:</span>
                                        <span style={{ wordBreak: 'break-word' }}>
                                          {extractPdfName(caseDetail[`bank_statement_${index + 4}`])}
                                        </span>
                                      </p>
                                      <br />
                                      {
                                        payslipReport.length > 0 && (
                                          <DataSection
                                            title="Matched Net Pay from Payslip"
                                            // data={netPayMatchData.data}
                                            data={filteredData}
                                            noDataMessage="Net pay transaction from payslip does not match in bank statement"
                                            color="red"
                                          />
                                        )
                                      }
                                      <br />
                                    </div>
                                  );
                                })
                              }
                            </div>
                          </div>

                          {/* Payslips Column */}
                          <div className='cs-column resp-w'>
                            <div className="tabs-container">
                              {
                                payslipReport.map((val, index) => (
                                  <button
                                    key={`payslip-${index}`}
                                    onClick={() => setTabs(prevState => ({
                                      ...prevState,
                                      activePayslip: index // Update activePayslip with the clicked index
                                    }))}
                                    className={`${index === tabs.activePayslip ? 'tab-button active-tab' : 'tab-button'}`}
                                  >
                                    Payslip {index + 1}
                                  </button>
                                ))
                              }
                            </div>

                            {payslipReport.map((val, index) => {
                              let nameMatch, addressMatch;
                              if (val?.matching && val?.matching?.length > 0) {
                                val?.matching?.forEach((val) => {
                                  if (val?.key === 'name') {
                                    nameMatch = val?.match
                                  } else if (val?.key === 'address') {
                                    addressMatch = val?.match
                                  }
                                })
                              }
                              if (index !== tabs.activePayslip) return null;

                              return (
                                <div key={index} className='custom-mb'>
                                  <div className='custom-mb'>
                                    <h4 className="custom-mb ">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Payslip Data</h4>
                                    <div className='custom-mb'><span className='card-span'>Filename:</span>
                                      <span style={{ wordBreak: 'break-word' }}>
                                        {extractPdfName(caseDetail[`payslip_${index + 1}`])}
                                      </span>
                                    </div>
                                    <span className='card-span'>Name on payslip:</span>
                                    <span className={`
                                    ${nameMatch === DETAIL_MATCH_STATUS.FULL ? 'full-match-text' : ''}
                                    ${nameMatch === DETAIL_MATCH_STATUS.PARTIAL ? 'partial-match-text' : ''}
                                    ${nameMatch === DETAIL_MATCH_STATUS.None ? 'none-match-text' : ''}
                                  `}>
                                      {val?.payslip_owner_name || errorNA}
                                    </span>
                                  </div>
                                  <div className='custom-mb'><span className='card-span'>Employee Number on payslip:</span>{validateNinEmpNoTaxCode(payslipReport[0]?.employee_no, payslipReport[1]?.employee_no, payslipReport[2]?.employee_no, index, 'Employee Number')}</div>
                                  <div className='custom-mb'><span className='card-span'>Address on payslip:</span><span className={`${addressMatch === DETAIL_MATCH_STATUS.FULL ? 'full-match-text' : addressMatch === DETAIL_MATCH_STATUS.PARTIAL ? 'partial-match-text' : addressMatch === DETAIL_MATCH_STATUS.None ? 'none-match-text' : ''}`}>{val?.payslip_owner_address || errorNA}</span></div>
                                  <div className='custom-mb'><span className='card-span'>Date:</span>{val?.date || errorNA}</div>
                                  <div className='custom-mb'><span className='card-span'>Period:</span>{val?.period || errorNA}</div>
                                  <div className='custom-mb'><span className='card-span'>Employer Name:</span>{val?.payslip_company_name || errorNA}</div>
                                  <div className='custom-mb'><span className='card-span'>Pay method on payslip:</span>
                                    {validatePaymentMethod(val)}
                                  </div>
                                  <div className='custom-mb'><span className='card-span'>Salary on payslip:</span>{val?.salary || errorNA}</div>
                                  <div className='custom-mb'><span className='card-span'>Net Pay on payslip:</span>{val?.net_pay || errorNA}</div>
                                  <div className='custom-mb'><span className='card-span'>Deduction on employees pension:</span>{val?.deductions?.employees_pension || 0}</div>
                                  <div className='custom-mb'>
                                    <span className='card-span'>Tax code:</span>
                                    {validateNinEmpNoTaxCode(payslipReport[0]?.tax_code, payslipReport[1]?.tax_code, payslipReport[2]?.tax_code, index, 'Tax code')}
                                  </div>
                                  <div className='custom-mb'><span className='card-span'>National insurance number:</span>{validateNinEmpNoTaxCode(payslipReport[0]?.national_insurance_number, payslipReport[1]?.national_insurance_number, payslipReport[2]?.national_insurance_number, index, 'National insurance number')}</div>
                                  <div className='custom-mb'><span className='card-span'>Employee Pension:</span>{val?.employee_to_date_totals?.employee_pension || errorNA}</div>
                                  <div className='custom-mb'><span className='card-span'>Student Loans:</span>{val?.employee_to_date_totals?.student_loans || errorNA}</div>
                                  {val?.additional_program_a_non_pensionable !== undefined && val?.additional_program_a_non_pensionable !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Additional program a non pensionable:</span>{val.additional_program_a_non_pensionable}
                                    </div>
                                  )}
                                  {val?.payroll_no !== undefined && val?.payroll_no !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Payroll Number:</span>{val.payroll_no}
                                    </div>
                                  )}
                                  {val?.on_call_cat_a_low_fr !== undefined && val?.on_call_cat_a_low_fr !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>On call cat a low fr:</span>{val.on_call_cat_a_low_fr}
                                    </div>
                                  )}
                                  {val?.course_fees !== undefined && val?.course_fees !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Course fees:</span>{val.course_fees}
                                    </div>
                                  )}
                                  {val?.night_duty !== undefined && val?.night_duty !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Night duty:</span>{val.night_duty}
                                    </div>
                                  )}
                                  {val?.flex_pt_trans_2_arrs !== undefined && val?.flex_pt_trans_2_arrs !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Flex pt trans 2 arrs:</span>{val.flex_pt_trans_2_arrs}
                                    </div>
                                  )}
                                  {val?.additional_roster_hours !== undefined && val?.additional_roster_hours !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Additional roster hours:</span>{val.additional_roster_hours}
                                    </div>
                                  )}
                                  {val?.commission !== undefined && val?.commission !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Commission:</span>{val.commission}
                                    </div>
                                  )}
                                  {val?.overtime !== undefined && val?.overtime !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Overtime:</span>{val.overtime}
                                    </div>
                                  )}
                                  {val?.bonus !== undefined && val?.bonus !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Bonus:</span>{val.bonus}
                                    </div>
                                  )}
                                  {val?.shift_pay !== undefined && val?.shift_pay !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Shift pay:</span>{val.shift_pay}
                                    </div>
                                  )}
                                  {val?.shift_allowance !== undefined && val?.shift_allowance !== null && (
                                    <div className='custom-mb'>
                                      <span className='card-span'>Shift Allowance:</span>{val.shift_allowance}
                                    </div>
                                  )}
                                  {
                                    val?.otx1 !== undefined && val?.otx1 !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Otx1:</span>{val.otx1}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.mind_and_body_allowance !== undefined && val?.mind_and_body_allowance !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Mind and body allowance:</span>{val.mind_and_body_allowance}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.car_cash !== undefined && val?.car_cash !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Car cash:</span>{val.car_cash}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.car_allowance !== undefined && val?.car_allowance !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Car allowance:</span>{val.car_allowance}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.travel_allowance !== undefined && val?.travel_allowance !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Travel allowance:</span>{val.travel_allowance}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.notional !== undefined && val?.notional !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Notional:</span>{val.notional}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.ot1 !== undefined && val?.ot1 !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Ot1:</span>{val.ot1}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.flex_cash !== undefined && val?.flex_cash !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Flex cash:</span>{val.flex_cash}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.flex_benifits_selected !== undefined && val?.flex_benifits_selected !== null  && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Flex benifits selected:</span>{val.flex_benifits_selected}
                                      </div>
                                    )
                                  }
                                  {
                                    val?.taxable_benifits !== undefined && val?.taxable_benifits !== null && (
                                      <div className="custom-mb">
                                        <span className='card-span'>Taxable benifits:</span>{val.taxable_benifits}
                                      </div>
                                    )
                                  }
                                  <div className='custom-mb'>
                                    <span className='card-span'>YTD:</span>{val?.employer_to_date_totals.taxable_pay_ytd || errorNA}
                                  </div>
                                  <div className='custom-mb'>
                                    <span className='card-span'>Calculated YTD:</span>{val?.employer_to_date_totals.taxable_pay_ytd_calculated || errorNA}
                                    {val?.employer_to_date_totals.taxable_pay_ytd !== val?.employer_to_date_totals.taxable_pay_ytd_calculated && <span className='error-red'> (YTD mismatch)</span>}
                                  </div>
                                  <br />
                                </div>
                              );
                            })}
                          </div>
                        </div>

                        {
                          (creditCardStatementInfo.length > 0 || jointAccountStatementInfo.length > 0) && (
                            <div className="cs-flex-container">
                              {/* Credit Card Statement Info */}
                              {
                                creditCardStatementInfo.length > 0
                                && (
                                  <div className="cs-column resp-w">
                                    <div className="tabs-container">
                                      {
                                        creditCardStatementInfo.map((val, index) => (
                                          index < 3 && (
                                            <button
                                              key={`credit-card-statement-${index}`}
                                              onClick={() => setTabs(prevState => ({
                                                ...prevState,
                                                activeCreditCardStatementInfo: index
                                              }))}
                                              className={`${index === tabs.activeCreditCardStatementInfo ? 'tab-button active-tab' : 'tab-button'}`}
                                            >
                                              Credit Statement {index + 1}
                                            </button>
                                          )
                                        ))
                                      }
                                    </div>

                                    {
                                      creditCardStatementInfo.map((val, index) => {

                                        if (index !== tabs.activeCreditCardStatementInfo) return null;

                                        return (
                                          <div className="custom-mb">
                                            <div className="custom-mb">
                                              <h4 className="custom-mb ">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Credit Card Statement Data</h4>
                                              <div className='custom-mb'><span className='card-span'>Name:</span>{val?.credit_card_statement_owner_name || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Account Number:</span>{val?.account_number || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Address:</span>{val?.credit_card_statement_owner_address || errorNA}</div>
                                              <div className='custom-mb'>
                                                <span className='card-span'>Filename:</span>
                                                <span style={{ wordBreak: 'break-word' }}>
                                                  {extractPdfName(caseDetail[`credit_card_statement_${index + 1}`])}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              }
                              {/* Joint Account Statement Info */}
                              {
                                jointAccountStatementInfo.length > 0
                                && (
                                  <div className="cs-column resp-w">
                                    <div className="tabs-container">
                                      {
                                        jointAccountStatementInfo.map((val, index) => (

                                          index < 3 && (
                                            <button
                                              key={`joint-acc-statement-${index}`}
                                              onClick={() => setTabs(prevState => ({
                                                ...prevState,
                                                activeJointAccountStatementInfo: index
                                              }))}
                                              className={`${index === tabs.activeJointAccountStatementInfo ? 'tab-button active-tab' : 'tab-button'}`}
                                            >
                                              Joint Acc Statement {index + 1}
                                            </button>
                                          )


                                        ))
                                      }
                                    </div>


                                    {
                                      jointAccountStatementInfo.map((val, index) => {

                                        if (index !== tabs.activeJointAccountStatementInfo) return null;

                                        return (
                                          <div className="custom-mb">
                                            <div className="custom-mb">
                                              <h4 className="custom-mb ">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Joint Account Statement Data</h4>
                                              <div className='custom-mb'><span className='card-span'>Name:</span>{val?.joint_account_statement_owner_name || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Account Number:</span>{val?.account_number || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Address:</span>{val?.joint_account_statement_owner_address || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Filename:</span>
                                                <span style={{ wordBreak: 'break-word' }}>
                                                  {extractPdfName(caseDetail[`joint_account_statement_${index + 1}`])}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              }
                            </div>
                          )
                        }

                        <div className='cs-flex-container'>
                          {!isEmptyObject(p60Data) && (
                            <>
                              <div className="custom-mb resp-w">
                                <h4 className="custom-mb">P60 Statement Data</h4>
                                <p className="custom-mb"><span className='card-span'>Employee Forename:</span>{p60Data?.employee_forename || errorNA}</p>
                                <p className="custom-mb"><span className='card-span'>Employee Surname:</span>{p60Data?.employee_surname || errorNA}</p>
                                <p className="custom-mb"><span className='card-span'>National Insurance Number:</span>{p60Data?.national_insurance_number || errorNA}</p>
                                <p className="custom-mb"><span className='card-span'>Tax code:</span>{p60Data?.tax_code || errorNA}</p>
                                <p className="custom-mb"><span className='card-span'>Employer Fullname:</span>{p60Data?.employer_full_name || errorNA}</p>
                                <p className="custom-mb"><span className='card-span'>Employer Address:</span>{p60Data?.employer_full_address || errorNA}</p>
                                <p className="custom-mb"><span className='card-span'>Tax year to 5 april X:</span>{p60Data?.tax_year_to_5_april || errorNA}</p>
                                <p className='custom-mb'><span className='card-span'>Pay and income tax</span>{p60Data?.pay_and_income_tax_details || errorNA}</p>
                                <p className="custom-mb"><span className='card-span'>To Employee Address:</span>{p60Data?.to_employee_address || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Filename:</span>
                                  <span style={{ wordBreak: 'break-word' }}>
                                    {extractPdfName(caseDetail['p60'])}
                                  </span>
                                </p>
                              </div>
                            </>
                          )}

                          {!isEmptyObject(employmentContract) && (
                            <>
                              <div className="custom-mb resp-w">
                                <h4 className="custom-mb">Employment Contract</h4>
                                <p className="custom-mb"><span className='card-span'>Start Date:</span>{employmentContract?.start_date || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Job title:</span>{employmentContract?.job_title || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Salary:</span>{employmentContract?.salary || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Employment Status:</span>{employmentContract?.employment_status || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Contract type:</span>{employmentContract?.contract_type || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Probation Period:</span>{employmentContract?.probation_period || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Employer Name:</span>{employmentContract?.employer_name || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Client Name:</span>{employmentContract?.client_name || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Signed By Both Parties:</span>{String(employmentContract?.signed_by_both_parties) || errorNA}</p>
                                <p className="custom-mb"><span className="card-span">Filename:</span>
                                  <span style={{ wordBreak: 'break-word' }}>
                                    {extractPdfName(caseDetail['employment_contract'])}
                                  </span>
                                </p>
                              </div>
                            </>
                          )
                          }
                        </div>

                        {
                          (taxOverView.length > 0 || taxCalculation.length > 0) && (
                            <div className="cs-flex-container">
                              {/* Tax Overview Column */}
                              {taxOverView.length > 0
                                && (
                                  <div className="cs-column resp-w">
                                    <div className="tabs-container">
                                      {
                                        taxOverView.map((val, index) => (
                                          <button
                                            key={`tax-overview-${index}`}
                                            onClick={() => setTabs(prevState => ({
                                              ...prevState,
                                              activeTaxOverview: index
                                            }))}
                                            className={`${index === tabs.activeTaxOverview ? 'tab-button active-tab' : 'tab-button'}`}
                                          >
                                            Tax year overview {index + 1}
                                          </button>
                                        ))
                                      }
                                    </div>
                                    {
                                      taxOverView.map((val, index) => {

                                        if (index !== tabs.activeTaxOverview) return null;

                                        return (
                                          <div className="custom-mb">
                                            <div className="custom-mb">
                                              <h4 className="custom-mb ">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Tax Year Overview Data</h4>
                                              <div className='custom-mb'><span className='card-span'>Tax:</span>{val?.tax || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>On behalf of:</span>{val?.on_behalf_of || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Tax year ending:</span>{val?.tax_year_ending || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Unique tax payer reference:</span>{val?.unique_tax_payer_reference || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Filename:</span>
                                                <span style={{ wordBreak: 'break-word' }}>
                                                  {extractPdfName(caseDetail[`tax_summary_${index + 1}`])}
                                                </span></div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }

                                  </div>

                                )
                              }
                              {/* Tax Calculation Column */}
                              {taxCalculation.length > 0
                                && (
                                  <div className="cs-column resp-w">
                                    <div className="tabs-container">
                                      {
                                        taxCalculation.map((val, index) => (
                                          <button
                                            key={`tax-calculation-${index}`}
                                            onClick={() => setTabs(prevState => ({
                                              ...prevState,
                                              activeTaxCalculation: index
                                            }))}
                                            className={`${index === tabs.activeTaxCalculation ? 'tab-button active-tab' : 'tab-button'}`}
                                          >
                                            Tax Calculation {index + 1}
                                          </button>
                                        ))
                                      }
                                    </div>
                                    {
                                      taxCalculation.map((val, index) => {

                                        if (index !== tabs.activeTaxCalculation) return null;

                                        return (
                                          <div className="custom-mb">
                                            <div className="custom-mb">
                                              <h4 className="custom-mb ">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Tax Year Calculation Data</h4>
                                              <div className='custom-mb'><span className='card-span'>Name:</span>{val?.client_name || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Year:</span>{val?.year || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>National Insurance Number:</span>{val?.ni_number || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Income:</span>{val?.income || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Pension deductions:</span>{val?.pension_deductions || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Student loan repayments due 31 Jan:</span>{val?.student_loan_repayments_due_31_Jan || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Unique tax payer reference:</span>{val?.unique_tax_payer_reference || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Total liability:</span>{val?.total_liability || errorNA}</div>
                                              <div className='custom-mb'><span className='card-span'>Filename:</span>
                                                <span style={{ wordBreak: 'break-word' }}>
                                                  {extractPdfName(caseDetail[`tax_calculation_${index + 1}`])}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      })
                                    }
                                  </div>
                                )
                              }
                            </div>

                          )
                        }

                        {
                          companyAccount.length > 0 && (
                            <div className="cs-flex-container">
                              <div className="cs-column resp-w">
                                <div className="tabs-container">
                                  {
                                    companyAccount.map((val, index) => (
                                      <button
                                        key={`company-account-${index}`}
                                        onClick={() => setTabs(prevState => ({
                                          ...prevState,
                                          activeCompanyAccount: index
                                        }))}
                                        className={`${index === tabs.activeCompanyAccount ? 'tab-button active-tab' : 'tab-button'}`}
                                      >
                                        Company Account {index + 1}
                                      </button>
                                    ))
                                  }
                                </div>

                                {
                                  companyAccount.map((val, index) => {

                                    if (index !== tabs.activeCompanyAccount) return null;

                                    return (
                                      <div className="custom-mb">
                                        <div className="custom-mb">
                                          <h4 className="custom-mb ">{index === 0 ? 'First' : index === 1 ? 'Second' : 'Third'} Company Account Data</h4>
                                          <div className='custom-mb'><span className='card-span'>Company name:</span>{val?.company_name || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>For the year ending:</span>{val?.for_the_year_ending || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Accountants name:</span>{val?.accountants_name || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Directors name:</span>{val?.directors_name || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Turnover:</span>{val?.turnover || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Gross profit:</span>{val?.gross_profit || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Profit before tax:</span>{val?.profit_before_tax || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Net profit:</span>{val?.net_profit || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Dividends:</span>{val?.dividends || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Directors salaries:</span>{val?.directors_salaries || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Average number of employees:</span>{val?.average_number_of_employees || errorNA}</div>
                                          <div className='custom-mb'><span className='card-span'>Filename:</span>
                                            <span style={{ wordBreak: 'break-word' }}>
                                              {extractPdfName(caseDetail[`company_account_${index + 1}`])}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </div>
                          )
                        }

                        {/** BUDGET PLANNER */}
                        {
                          budgetAnalytics.length > 0 && (
                            <>
                              <div className="custom-mb">
                                <div style={{ marginTop: '1rem', padding: '5px' }}>
                                  <h3 style={{ textAlign: 'center', color: '#7856FF' }}>BUDGET PLANNER ANALYTICS</h3>
                                  <div>
                                    {
                                      budgetAnalytics && budgetAnalytics.length > 0 && (
                                        <>
                                          <h4 style={{
                                            // textDecoration:"underline",
                                            marginBottom: "8px"
                                          }}>
                                            Average paid out amounts
                                          </h4>
                                          {
                                            budgetAnalytics.map((val) => {
                                              return (
                                                <>
                                                  <p className='custom-mb'>
                                                    <span className='card-span'>{formatBudgetCategoryName(val?.category)}:</span>{Number(val?.total_paid_out).toFixed(2) > 0 ? Number(val?.total_paid_out).toFixed(2) : 'Transaction missing'}
                                                  </p>
                                                </>
                                              )
                                            })
                                          }
                                        </>
                                      )
                                    }
                                  </div>
                                  {/* <div className="table-container">
                                    <table className='transaction-table'>
                                      <thead>
                                        <tr className='table-row'>
                                          <th className='table-head'>S.No.</th>
                                          <th className='table-head'>Date</th>
                                          <th className='table-head'>Payment Method</th>
                                          <th className='table-head'>Transaction Name</th>
                                          <th className='table-head'>Paid Out</th>
                                          <th className='table-head'>Paid In</th>
                                          <th className='table-head'>Category</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {
                                          budgetPlanner.map((val, index) => {
                                            return (
                                              <>
                                                <tr className='table-row'>
                                                  <td className='table-data'>{index + 1 + (paginationData.pageNo - 1) * paginationData.limit}</td>
                                                  <td className='table-data'>{val.date || 'NA'}</td>
                                                  <td className='table-data'>{val.payment_method || 'NA'}</td>
                                                  <td className='table-data'>{val.description || 'NA'}</td>
                                                  <td className='table-data'>{val.paid_out}</td>
                                                  <td className='table-data'>{val.paid_in}</td>
                                                  <td className='table-data'>{val.category}</td>
                                                </tr>
                                              </>
                                            )
                                          })

                                        }
                                      </tbody>
                                    </table>
                                    <ReactPaginate
                                      pageCount={paginationData.totalPages}
                                      pageRangeDisplayed={2}
                                      marginPagesDisplayed={3}
                                      forcePage={paginationData.pageNo - 1}
                                      onPageChange={handlePageClick}
                                      containerClassName={'pagination'}
                                      activeClassName={'active'}
                                    />
                                  </div> */}
                                </div>
                              </div>
                            </>
                          )
                        }
                        {/** BUDGET PLANNER */}


                        {/** FRAUD DETECTION  */}
                        {
                          fraudDetection.length > 0 && (
                            <div className="fraud-detection-container">
                              <div className="fraud-detection-box">
                                <h3 className="fraud-detection-heading">⚠️ FRAUD DETECTION AND ANOMALIES</h3>
                                <h4 className="fraud-detection-subheading">
                                  Purpose: Highlighting potential fraud indicators and anomalies.
                                </h4>
                                <ol className="fraud-detection-list">
                                  {fraudDetection.map((val, index) => (
                                    <li key={index} className="fraud-detection-item">
                                      {val}
                                    </li>
                                  ))}
                                </ol>
                              </div>
                            </div>
                          )
                        }
                        {/** FRAUD DETECTION */}

                        {/** CHART  */}
                        <ScoreChart totalScore={caseDetail?.score} />
                        {/** CHART */}

                        {/** VULNERABILITY DETECTION */}
                        {
                          vulnerabilityDetection.length > 0 && (
                            <div className="fraud-detection-container">
                              <div className="fraud-detection-box">
                                <h3 className="fraud-detection-heading">⚠️ VULNERABILITY DETECTION</h3>
                                <h4 className="fraud-detection-subheading">
                                  Purpose: Highlighting vulnerabilities.
                                </h4>
                                <ol className="fraud-detection-list">
                                  {vulnerabilityDetection.map((val, index) => (
                                    <li key={index} className="fraud-detection-item">
                                      {val}
                                    </li>
                                  ))}
                                </ol>
                              </div>
                            </div>
                          )
                        }
                        {/** VULNERABILITY DETECTION */}


                        <div className="custom-mb">
                          <div className="cs-btn-container">
                            <div className='bs-transactions' onClick={() => navigate(`/case-transactions?id=${caseId}`)}>View Bank Statement Transactions</div>
                            <div className='bs-transactions' onClick={() => generateBudgetPlanner()}>
                              {budgetAnalytics.length > 0 ? "Re-Generate Budget Planner" : "Generate Budget Planner"}
                            </div>
                          </div>
                          <p className='card-span' style={{ marginTop: '10px', marginLeft: '5px' }}>Mortgage AI can make mistakes. Please double check responses.</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <h1 className='no-data'>No case data</h1>
                  )
                }
              </div>
            </>

          )
      }
    </>
  )
}

export default CaseDetail