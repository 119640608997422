const authToken = () => ({
  headers: {
    "Content-type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
  timeout: "3000000", //50 minutes
});

const authTokenFormData = () => ({
  headers: {
    "Content-type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
  timeout: "3000000", //50 minutes
});

export { authToken, authTokenFormData };
