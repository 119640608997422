import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import App from './App';
import { store } from './redux/store/store';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
    {process.env.REACT_APP_ENVIRONMENT === 'test' ? (
          <BrowserRouter basename='/demo/mortgage-ai'>
            <App />
          </BrowserRouter>
        ) : (process.env.REACT_APP_ENVIRONMENT === 'production' ? (
          <BrowserRouter>
            <App />
          </BrowserRouter>
        ) : (
          <BrowserRouter>
            <App />
          </BrowserRouter>
        ))}
    <ToastContainer position="top-center" pauseOnHover={false} theme="colored" autoClose={2000}/>
    </Provider>
  // </React.StrictMode>
);